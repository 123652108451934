* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background: #17182f;
}

.App {
  display: flex;
}
